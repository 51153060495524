export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SEARCH_BOX_CLASS: 'search search-modal__form',
  SEARCH_BOX_INPUT_CLASS: 'search__input',
  SEARCH_BOX_BUTTON_CLASS: 'search__button field__button',
  BROWSE_ALL: 'Search for "{{query}}"',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  PRODUCT_REPEATER_CLASS: 'grid product-grid grid--2-col-tablet-down grid--4-col-desktop',

  GARAGE_SIZE:
    '<div class="garage-size cart-count-bubble" key="garage-size"><span aria-hidden="true">{{size}}</span><span class="visually-hidden">{{size}} items</span></div>',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  RELATED_PRODUCT_LIST_CLASS: 'grid',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
